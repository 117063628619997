import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';

import { createListing, updateMyListings } from '../../../utilities/api';
import { toggleElement, formatCurrency, getSinglarOrPluralWord } from '../../../utilities/helpers';
import { useWindowSize } from '../../../utilities/hooks';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { BackButton } from '../../BackButton';
import { DisplayTickets } from '../DisplayTickets';
import { Numpad } from './Numpad';
import { SuccessContainer } from '../SuccessContainer';

import { SuccessDisclaimer } from '../SuccessDisclaimer';
import { Spinner } from "../../LoadingContainer/Spinner"

export default function SellModal({ handleClose, setTicketStatus, ticketAction, order, listing, getListings, getMyOrders }) {

    const windowSize = useWindowSize();

    // 1 - sell 
    // 2 - price 
    // 3 - summary 
    // 4 - success 
    const [
        step,
        setStep
    ] = useState(ticketAction === 'edit' ? 2 : 1);

    const [
        isUpdate,
        setIsUpdate
    ] = useState(false);

    const [label, setLabel] = useState('Price per ticket')

    const [initialAskingPrice, setInitialAskingPrice] = useState();

    const [price, setPrice] = useState(0);

    const [priceValid, setPriceValid] = useState(true)

    const [serviceFees, setServiceFees] = useState(0)

    // selected tickets
    const [
        selectedTickets,
        setSelectedTickets
    ] = useState([]);

    const [isSaving, setIsSaving] = useState(false)

    const [isDisabled, setIsDisabled] = useState(false)

    const ticketsTotalPrice = selectedTickets.map((ticket) => ticket.cost).reduce((a, v) => a + v, 0);
    const ticketPrice = ticketsTotalPrice / selectedTickets.length

    useEffect(() => {
        console.log("order: ", order)
        console.log("listing: ", listing)
    }, [order, listing])

    useEffect(() => {
        console.log("selectedTickets: ", selectedTickets)
    }, [selectedTickets])

    useEffect(() => {
        const obj = order || listing
        console.log(obj?.event?.resaleEnableMinimum, ticketPrice);
        console.log(obj?.event?.resaleMinimumPercentage);
        const amountOfPrice = parseFloat(ticketPrice) * (Number(obj?.event?.resaleMinimumPercentage) / 100);
        console.log(amountOfPrice);
        const minResalePrice = ticketPrice + amountOfPrice
        if (obj?.event?.resaleEnableMinimum && price > 0 && minResalePrice && (price < (minResalePrice))) {
            console.log(price, minResalePrice);
            setLabel(`Pice must be at least ${formatCurrency(minResalePrice)}`);
            setPriceValid(false)
        } else {
            setLabel("Price per ticket")
            setPriceValid(true)
        }

        // update fees when price changes
        let fees;

        fees = (obj.event?.fee_structure.secondaryServiceFeeSeller / 100) * parseFloat(price).toFixed(2)
        setServiceFees((fees).toFixed(2))
    }, [order, price, listing])

    useLayoutEffect(() => {
        let el = document.querySelector('.btn-close');

        if (el) {
            if (step === 4)
                toggleElement(el, false)

            return () => {
                toggleElement(el, true)
            }
        };
    }, [step])

    useEffect(() => {
        // set ticket price
        if (ticketAction === 'edit') {
            setIsUpdate(true)
            setSelectedTickets(listing.tickets)
            setInitialAskingPrice(listing.askingPrice)
            setPrice(listing.askingPrice)
        }
    }, [])

    useEffect(() => {
        // on edit
        if (ticketAction === 'edit') setIsDisabled(parseFloat(price) === initialAskingPrice)
    }, [initialAskingPrice, price])

    const handleGoBack = () => {
        setStep(step - 1)
    }

    const handleClick = () => {
        handleClose();
        if (!isUpdate && step === 4) setTicketStatus('listed')
    };

    const submit = () => {
        setIsSaving(true)
        let data = {
            tickets: selectedTickets,
            quantity: selectedTickets.length,
            askingPrice: parseFloat(price),
            event: order?.event,
            serviceFees: parseFloat(serviceFees * selectedTickets?.length).toFixed(2),
            payout: ((parseFloat(price).toFixed(2) * selectedTickets?.length) - (parseFloat(serviceFees * selectedTickets.length).toFixed(2))),
            fromOrder: order?.id,
            type: "GA"
        }

        // ADD CONSTRAINT THAT TICKETS NEED TO BE SAME SECTION, SAME ROW AND ADJACENT
        // ALSO SAME OFFER

        // Find the first matching ticket
        // This assumes tickets are in the same row and section, need to add that constraint... 
        const matchingTicket = selectedTickets[0]
        console.log("matchingTicket: ", matchingTicket);
        console.log("order: ", order);
        if (matchingTicket) {
            // This is not 100% fitting, fucks up in ga-sections
            if (matchingTicket.rowId && matchingTicket.sectionId) {
                data.rowId = matchingTicket.rowId;
                data.sectionId = matchingTicket.sectionId;
                data.sectionNumber = matchingTicket.sectionNumber;
                data.rowNumber = matchingTicket.rowNumber;
                data.offer = order?.details?.event?.offers.find((offer) => offer.id === matchingTicket.associatedOfferId)
                data.type = "SEATED";
            }
        }

        console.log("sellmodal createlisting:")
        console.log("payout: ", data.payout)
        console.log("fromOrder:", data.fromOrder)
        if (ticketAction === 'edit') {
            data.event = listing.event
            updateMyListings(listing.id, data)
                .then((res) => {
                    setIsSaving(false)
                    getListings().then(() => {
                        setIsSaving(false)

                        if (!isSaving) setStep(4)
                    })
                })
                .catch((err) => {
                    console.error(err)
                    setIsSaving(false)
                })
        } else {
            createListing(data)
                .then((res) => {
                    getMyOrders().then(() => {
                        setIsSaving(false)

                        if (!isSaving) setStep(4)
                    })
                })
                .catch((err) => {
                    setIsSaving(false)
                    console.error(err)
                })
        }
    }

    const shouldShow = () => {
        if (!isUpdate) {
            // sell ticket 
            return order?.tickets?.length > 0
        } else {
            // update ticket price 
            return true
        }
    }

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title as="h5"> {isUpdate ? 'Edit' : 'Sell'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 1 && (
                    <>
                        <DisplayTickets status="sell" role="select" setSelectedTickets={setSelectedTickets} tickets={order?.tickets} />
                        <Stack direction="horizontal" className="btn-group-flex">
                            <Button onClick={() => setStep(2)} disabled={selectedTickets.length === 0} className="btn-next" size="lg">
                                Set price
                            </Button>
                        </Stack>
                    </>
                )}

                {step === 2 && (
                    <>
                        <div className="modal-body-heading">
                            <h4 className="modal-body-heading-title mb-2">Price your {getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()}</h4>
                            <p className="small text-muted fw-medium">
                                Ticket face value {formatCurrency(ticketPrice)}
                            </p>
                        </div>
                        <Form.Group controlId='price' className="form-card form-card-lg bg-info">
                            <Form.Label className={!priceValid ? 'text-danger' : ''}>{label}</Form.Label>
                            <Form.Control
                                readOnly={windowSize < 768}
                                type="text"
                                value={`$${price}`}
                                maxLength="7"
                                onChange={(e) => setPrice(e.target.value.substring(1).trim())}
                                required
                                autoFocus
                            />
                        </Form.Group>
                        {windowSize < 768 && (
                            <Numpad price={price} setPrice={setPrice} />
                        )}
                        <Stack direction="horizontal" className="btn-group-flex">
                            {!isUpdate && (<BackButton variant="default" handleGoBack={handleGoBack} />)}
                            <Button onClick={() => setStep(3)} className="btn-next" disabled={price === 0 || !price || !priceValid || isDisabled} size="lg">{!isUpdate ? 'Payout summary' : 'Update price'}</Button>
                        </Stack>
                    </>
                )}
                {step === 3 && (
                    <>
                        <div className="modal-body-heading--with-border">
                            <h4 className="modal-body-heading-title mb-2">Payment Summary</h4>
                            <p>
                                Please go to 'Settings' in your 'Wallet' and make sure your bank account is linked in order to receive funds from ticket sales.
                            </p>
                        </div>

                        <div>
                            <h2 className="normal text-uppercase text-muted mb-3">Ticket Breakdown</h2>
                            <ul>
                                <li className="list">
                                    <p className="heading">Tickets</p>
                                    <ul>
                                        <Stack as="li" direction="horizontal" className="split-row">
                                            <span>Tickets: {formatCurrency(price)} x {selectedTickets?.length}</span>
                                            <span className='text-end'>{formatCurrency(price * selectedTickets?.length)}</span>
                                        </Stack>
                                    </ul>
                                </li>
                                <li className="list">
                                    <p className="heading">Service Fees</p>
                                    <ul>
                                        <Stack as="li" direction="horizontal" className="split-row">	<span>Service Fees: {formatCurrency(serviceFees)} x {selectedTickets?.length}</span>
                                            <span className='text-end'>(-{formatCurrency(serviceFees * selectedTickets?.length)})</span>
                                        </Stack>
                                    </ul>
                                </li>
                                <Stack direction='horizontal' as="li" className="split-row list">
                                    <span className="heading m-0">Your Payout</span>
                                    <span className="text-end fw-medium">{formatCurrency((price * selectedTickets?.length) - parseFloat(serviceFees * selectedTickets?.length))}</span>
                                </Stack>
                            </ul>
                        </div>

                        <div className="mt-auto mt-md-4">
                            <small className="disclaimer mb-3">By clicking 'Agree and sell' you are constenting to Blocktickets <a href="https://blocktickets.xyz/terms-conditions" target="_blank">terms and conditions</a>. </small>
                            <Stack direction="horizontal" className="mt-0 btn-group-flex">
                                <BackButton variant="default" handleGoBack={handleGoBack} />
                                <Button
                                    onClick={submit}
                                    className={`icon-button btn-h
                                ${!isSaving ? 'btn-next' : ''}`}
                                    size="lg"
                                    disabled={isSaving}>{isSaving ? (
                                        <Spinner />
                                    ) : (
                                        'Agree and sell'
                                    )}</Button></Stack></div>


                    </>
                )}
                {step === 4 && (
                    <>
                        <SuccessContainer>
                            <h4 className="modal-body-heading-title">
                                {isUpdate ? 'Your ticket price has been updated' : `Your ${getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()} ${isPlural(selectedTickets.length) ? 'are' : 'is'} listed for sale!`}
                            </h4>
                        </SuccessContainer>
                        <p className="small">
                            {isUpdate ? `Your updated price will be in effect within 2 hours on the marketplace. If your ${getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()} ${isPlural(selectedTickets.length) ? 'are' : 'is'} sold before the price is updated you will receive funds based on the original price.` : `We will notify you via sms if a purchase is made. While your ${getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()} ${isPlural(selectedTickets.length) ? 'are' : 'is'} listed, you can change the price or delist them from the marketplace in 'My listings' at anytime`}
                        </p>
                        <SuccessDisclaimer />
                        <Stack className="btn-group-flex">
                            {!isUpdate && (<Link to="/my-listings" className="btn btn-lg btn-outline-light">Go to My listings</Link>)}
                            {shouldShow() && (<Button onClick={handleClick} size='lg'>Close</Button>)}
                        </Stack>
                    </>
                )}</Modal.Body>
        </Fragment>
    );
}
