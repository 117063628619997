
import { useState } from 'react'

import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import './legend.scss';

export default function Legend() {

    const [open, setOpen] = useState(false);

    return (
        <div className='vjx-legend' id="legend-container">
            <Button variant="link" className="btn-toggle"
                onClick={() => setOpen(!open)}
                aria-controls="legend"
                aria-expanded={open}
            >
                Legend
            </Button>
            <Collapse in={open}>
                <div id="legend">
                    <ul id="legend-list">
                        <li className="list-item unavailable">Unavailable</li>
                        <li className="list-item available">Available</li>
                        <li className="list-item accessible">Accessibility</li>
                        <li className="list-item resale">Verified Resale</li>
                    </ul>
                </div>
            </Collapse>
        </div>
    )
}