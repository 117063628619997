import Stack from "react-bootstrap/Stack";

import { useTickets } from "../../../providers/TicketsProvider/TicketsProvider";
import { useModals } from "../../../providers/ModalsProvider/ModalsProvider";

import { timezones } from "../../../../../../utilities/helpers";

import { TicketGroup } from "./TicketGroup";
import { Countdown } from "../../../../components/Countdown";
import { GetSoldout } from "../../../../components/GetStatus/GetSoldout";
import { Spinner } from "../../../../../LoadingContainer/Spinner";

export default function ListingsContainer({
  isMappingCreated,
  isScheduled,
  scheduledTime,
}) {
  const { openCBModal, setViewingSection } = useModals();
  const {
    isLoadingTickets,
    quickpicks,
    soldout,
    event,
    reloadQuickPicks,
    needToUnlock,
  } = useTickets();

  let timezone = timezones(event?.venue?.timezone);

  if (isLoadingTickets) {
    return (
      <Stack className='align-items-center'>
        <Spinner variant='primary' size='lg' />
      </Stack>
    );
  }

  // Should have a uniform padding here
  // now each element has or does not have a padding of their own...
  return (
    <ContentRenderer
      isMappingCreated={isMappingCreated}
      isScheduled={isScheduled}
      scheduledTime={scheduledTime}
      timezone={timezone}
      quickpicks={quickpicks}
      soldout={soldout}
      reloadQuickPicks={reloadQuickPicks}
      openCBModal={openCBModal}
      setViewingSection={setViewingSection}
      event={event}
      needToUnlock={needToUnlock}
    />
  );
}

const ContentRenderer = ({
  isMappingCreated,
  isScheduled,
  scheduledTime,
  timezone,
  quickpicks,
  soldout,
  reloadQuickPicks,
  openCBModal,
  setViewingSection,
  event,
  needToUnlock,
}) => {
  // Should not happen, perhaps change this to an ERROR state
  if (!isMappingCreated) {
    return (
      <StateMessage
        title='Event is getting ready... Hang tight!'
        description='Event is not available yet. Check back in a few minutes while we get this event set!'
      />
    );
  }

  if (isScheduled) {
    return (
      <Countdown
        scheduledTime={scheduledTime}
        timezone={timezone}
        reloadQuickPicks={reloadQuickPicks}
      />
    );
  }

  if (soldout) {
    return <GetSoldout icon={true} fixedMobile={false} />;
  }

  if (needToUnlock) {
    return (
      <StateMessage
        title='All available offers are locked...'
        description='Please try adjusting your filter preferences'
      />
    );
  }

  if (!quickpicks?.length) {
    return (
      <StateMessage
        title='No results for your filter'
        description='Please try adjusting your filter preferences'
      />
    );
  }

  return (
    <div className='flex-one pxb'>
      <Stack as='ul' gap={2} className='tickets-col'>
        {quickpicks.map((pick, i) => {
          return (
            <TicketGroup
              key={i}
              id={i.toString()}
              pick={pick}
              openCBModal={openCBModal}
              setViewingSection={setViewingSection}
            />
          );
        })}
      </Stack>
    </div>
  );
};

const StateMessage = ({ icon = true, title, description }) => (
  <div className='vjx-no-results'>
    {icon && <InfoIcon />}
    <p>{title}</p>
    {description && <span>{description}</span>}
  </div>
);

const InfoIcon = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99935 16.6665C13.6812 16.6665 16.666 13.6817 16.666 9.99984C16.666 6.31794 13.6812 3.33317 9.99935 3.33317C6.31745 3.33317 3.33268 6.31794 3.33268 9.99984C3.33268 13.6817 6.31745 16.6665 9.99935 16.6665ZM9.99935 18.3332C14.6017 18.3332 18.3327 14.6022 18.3327 9.99984C18.3327 5.39746 14.6017 1.6665 9.99935 1.6665C5.39698 1.6665 1.66602 5.39746 1.66602 9.99984C1.66602 14.6022 5.39698 18.3332 9.99935 18.3332Z'
      fill='#777E91'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99935 5.8335C9.53911 5.8335 9.16602 6.20659 9.16602 6.66683C9.16602 7.12707 9.53911 7.50016 9.99935 7.50016C10.4596 7.50016 10.8327 7.12707 10.8327 6.66683C10.8327 6.20659 10.4596 5.8335 9.99935 5.8335ZM9.99935 9.16683C9.53911 9.16683 9.16602 9.53993 9.16602 10.0002V13.3335C9.16602 13.7937 9.53911 14.1668 9.99935 14.1668C10.4596 14.1668 10.8327 13.7937 10.8327 13.3335V10.0002C10.8327 9.53993 10.4596 9.16683 9.99935 9.16683Z'
      fill='#777E91'
    />
  </svg>
);
