import { ModalTop } from "../../../../components/ModalTop";
import { Suspense } from "react";
import "./seatmapModal.scss";

export default function SeatmapModal({
  dialogRef,
  hideSeatmap,
  title,
  data,
  setData,
  dialogOpen,
  background,
  SeatmapWrapper
}) {

  return (
    <dialog ref={dialogRef} className={`dialog-sm-base`}>
      <div className='dialog-sm-inner'>
        <ModalTop
          title={title}
          handleClose={hideSeatmap}
          isSeatmapModal={true}
        />
        <div className='sm-content'>
          <Suspense fallback={null}>
            {dialogOpen && (
              <SeatmapWrapper
                data={data}
                setData={setData}
                handleClose={hideSeatmap}
                background={background}
              />
            )}
          </Suspense>
        </div>
      </div>
    </dialog>
  );
}
