import React, { Fragment, useEffect, useLayoutEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import LoadingContext from '../../context/Loading/Loading';

import { getPackage } from '../../utilities/api';

import { PageLoadingContainer, ViewPackageWrapper } from '../../components';

export default function ViewPackagePage() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    let { id } = useParams();

    const [eventPackage, setEventPackage] = useState()

    useLayoutEffect(() => {
        const el = document.getElementById('main-container')
        el.classList.add('lg-container')

        return () => {
            el.classList.remove('lg-container')
        }
    }, [])

    useEffect(() => {
        showLoading()
        getPackage(id)
            .then((res) => {
                setEventPackage(res.data)
            })
            .catch((err) => console.error(err))
            .finally(() => hideLoading())
    }, [id]);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="keywords" content={`Calendar | Buy tickets and find event information for upcoming events by ${eventPackage?.venue?.name}`} />
                <title>{`${eventPackage?.venue?.name} | Event Calendar`}</title>
                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`${window.location.href}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${eventPackage?.venue?.name} | Event Calendar`} />
                <meta property="og:description" content={`Calendar | Buy tickets and find event information for upcoming events by ${eventPackage?.venue?.name}`} />
                <meta property="og:image" content={eventPackage?.venue?.image[0]?.url} />
                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content={`${window.location.host}`} />
                <meta property="twitter:url" content={`${window.location.href}`} />
                <meta name="twitter:title" content={`${eventPackage?.venue?.name} | Event Calendar`} />
                <meta name="twitter:description" content={`Calendar | Buy tickets and find event information for upcoming events by ${eventPackage?.venue?.name}`} />
                <meta name="twitter:image" content={eventPackage?.venue?.image[0]?.url} />
            </Helmet>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='background-gradient py-4 full-height'>
                    <ViewPackageWrapper eventPackage={eventPackage} />
                </section>
            )}
        </Fragment>
    );
}
