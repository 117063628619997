import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./specialDiscount.scss";
import { QuickpickBadge } from "../../../NewSeatedPurchaseWrapper/NewSeatedPurchaseContainer/TicketSelectionPanel/ListingsContainer/TicketGroup/TicketGroup";

export default function SpecialDiscount({ tickets, closeModal }) {
    const [selectedDiscount, setSelectedDiscount] = useState("");
    const [selectedTickets, setSelectedTickets] = useState([]);
  
    const discountOptions = [
      { value: "veteran", label: "Veteran discount" },
      { value: "child", label: "Child under 10yo" },
    ];
  
    const handleTicketSelection = (ticketId) => {
      setSelectedTickets(prev => 
        prev.includes(ticketId)
          ? prev.filter(id => id !== ticketId)
          : [...prev, ticketId]
      );
    };
  
    return (
      <div className='special-discount-modal'>
        <div className='special_discount_title'>
          <h4>Special discount</h4>
          <p>Applicable to Veterans, children under 18 or special category</p>
        </div>
        <div className='vjx-select-bxo'>
          <label>Select what applies to you</label>
          <Form.Select
            value={selectedDiscount}
            onChange={(e) => setSelectedDiscount(e.target.value)}
          >
            <option value=''>Select an option</option>
            {discountOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        </div>
  
        {selectedDiscount && (
          <div className='ticket-selection'>
            <h6>Add discount to your ticket(s).</h6>
            <div className='tickets-list'>
              <p>Select tickets to apply the discount:</p>
              {tickets && tickets.map((ticket) => (
                <div 
                  key={ticket.id} 
                  className={`ticket-item ${selectedTickets.includes(ticket.id) ? 'selected' : ''}`}
                  onClick={() => handleTicketSelection(ticket.id)}
                >
                  <div className="vjx-img"></div>
                  <div className="vjx-ticket-item-inner">
                      <QuickpickBadge
                        isListing={false}
                        inventoryType={"open"}
                        offerName={"Standard admission"}
                      />
                      <p>$99.00 each <span>Incl. Taxes</span></p>
                      <p>Hot seat VIP Package</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
  
        <div className='important-info'>
          <div style={{width: '24px', height: '24px'}}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
                fill='#3E8BF7'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 7C11.4477 7 11 7.44772 11 8C11 8.55229 11.4477 9 12 9C12.5523 9 13 8.55229 13 8C13 7.44772 12.5523 7 12 7ZM12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12C13 11.4477 12.5523 11 12 11Z'
                fill='#3E8BF7'
              />
            </svg>
          </div>
          <div>
            <h6>Important information</h6>
            <p>
              This offer is only valid with a Veteran ID card, which will be
              required for entry. Please ensure you bring your ID, as access to
              the venue will not be granted without it
            </p>
          </div>
        </div>
        <Modal.Footer>
          <Button variant='outline-light' size={"lg"} onClick={closeModal}>
            Cancel
          </Button>
          <Button 
            variant='primary' 
            size={"lg"} 
            disabled={!selectedDiscount || selectedTickets.length === 0} 
            onClick={closeModal}
          >
            Add discount
          </Button>
        </Modal.Footer>
      </div>
    );
  }
