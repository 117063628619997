import React, { useMemo, useRef, useState } from "react";
import { useTooltips } from "../../../providers/TooltipProvider";

const Seat = React.memo(
  ({ seat, selectSpecificSeat, unselectSeat, seatLookupTable }) => {
    const TOOLTIP_TIMEOUT = 500;
    const seatRef = useRef(null);
    const ticketGroup = useMemo(
      () => seatLookupTable[seat.seatId],
      [seatLookupTable, seat.seatId]
    );

    const [hoverTimeout, setHoverTimeout] = useState(null);
    const { showTooltipFromSeat, closeIfSpecificTooltip } = useTooltips();

    const handleSeatClick = () => {
      if (
        ticketGroup?.offer?.accessCode &&
        ticketGroup?.offer?.accessCode !== null &&
        !ticketGroup?.offer?.unlocked
      ) {
        let simulatedInventory = [
          {
            availableCount: 1,
            ...ticketGroup,
          },
        ];
        showTooltipFromSeat(
          seatRef.current,
          seat.seatId,
          5,
          undefined,
          simulatedInventory
        );
      } else if (window.innerWidth <= 768) {
        showTooltipFromSeat(
          seatRef.current,
          seat.seatId,
          2,
          ticketGroup,
          undefined
        );
      } else {
        selectSpecificSeat(seat.seatId, ticketGroup);
      }
    };

    const handleMouseEnter = () => {
      if (
        window.innerWidth <= 768 ||
        (ticketGroup?.offer?.accessCode &&
          ticketGroup?.offer?.accessCode !== null &&
          !ticketGroup?.offer?.unlocked)
      ) {
        return;
      }

      const timeout = setTimeout(() => {
        showTooltipFromSeat(
          seatRef.current,
          seat.seatId,
          1,
          ticketGroup,
          undefined
        );
      }, TOOLTIP_TIMEOUT);
      setHoverTimeout(timeout);
    };

    const handleMouseLeave = () => {
      if (window.innerWidth <= 768) {
        return;
      }

      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
      closeIfSpecificTooltip(1); // close if showing SeatTooltip
    };

    const seatColor = useMemo(() => {
      if (seat.selected) return "#45B26B";
      if (!ticketGroup) return "#E6E8EC";
      if (
        ticketGroup?.offer &&
        ticketGroup?.offer.accessCode !== null &&
        !ticketGroup?.offer?.unlocked
      )
        return "#353945";
      return ticketGroup.resale
        ? "#E06C35"
        : ticketGroup?.offer?.inventoryType === "open"
        ? "#3E8BF7"
        : "#9757D7";
    }, [seat.selected, ticketGroup]);

    const seatProps = {
      ref: seatRef,
      id: seat.seatId,
      className: `seat ${ticketGroup && "--hover"}`,
      x: seat.cx,
      y: seat.cy,
      rx: 6,
      ry: 6,
      width: seat.w,
      height: seat.h,
      fill: seatColor,
      style: { cursor: ticketGroup ? "pointer" : "default" },
      ...(ticketGroup && {
        onClick: seat.selected
          ? () => unselectSeat(seat.seatId, ticketGroup)
          : handleSeatClick,
        onMouseEnter: seat.selected ? undefined : handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }),
    };

    const getIconId = () => {
      if (seat.selected) return "icon-selected";
      if (ticketGroup && ticketGroup.resale) return "icon-resale";
      if (ticketGroup && ticketGroup?.offer?.unlocked) return "icon-unlocked";
      if (ticketGroup && ticketGroup?.offer?.accessCode) return "icon-locked";
      if (ticketGroup && ticketGroup?.offer?.inventoryType === "exclusive")
        return "icon-vip";
      return null;
    };

    const iconId = getIconId();
    return (
      <g>
        <rect {...seatProps} />
        {iconId && (
          <use
            href={`#${iconId}`}
            x={seat.cx + seat.w * 0.2}
            y={seat.cy + seat.h * 0.2}
            width={seat.w * 0.6}
            height={seat.h * 0.6}
            pointerEvents={"none"}
            fill='white'
          />
        )}
      </g>
    );
  }
);

export default Seat;
