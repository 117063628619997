import React from 'react';
import moment from "moment"

import { formatDateTime, formatShortDate, getTimezoneDate } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

// used on package venue profile and my events details
export default function PackageEvent({ event, venue, timezone, styles, children }) {
    return (
        <Card className={`card-list ${styles || ''}`}>
            <Card.Body>
                <Stack className='card-date align-self-center'>
                    <span className='month caption--uppercase'>{formatShortDate(getTimezoneDate(event?.start, timezone), 'monthOnly')}</span>
                    <span className='day normal normal-bold'>{formatShortDate(getTimezoneDate(event?.start, timezone), 'dayOnly')}</span>
                </Stack>
                <Stack gap={2} className='justify-content-center'>
                    <Card.Title as="h5" className='card-title-sm mb-0'>{event?.name}</Card.Title>
                    <Card.Subtitle as="h6" className='card-subtitle--light'>
                        <span>{formatShortDate(getTimezoneDate(event?.start, timezone), 'weekdayOnly')}</span>, {" "}
                        <span>{formatDateTime(getTimezoneDate(event?.start, timezone), 'timeOnly')}</span>
                        <span className='venue'>{venue?.name}</span>
                    </Card.Subtitle>
                </Stack>
                {children}
            </Card.Body>
        </Card>
    )
}