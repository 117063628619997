import React, { useState } from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { VenueBanner } from './VenueBanner';
import { VenueCard } from './VenueCard';
import { UpcomingEventsList, PackagesList } from './Lists';

import './venueInformation.scss';

export default function VenueInformation({ venue, packages }) {

    const [
        key,
        setKey
    ] = useState('upcoming');

    return (
        <div id="venue">
            {venue && (
                <>
                    <header>
                        <VenueBanner venue={venue} />
                    </header>
                    <section className="spacer-xs">
                        <Row className="gap-5 gap-md-3">
                            <Col md={5} lg={4} xxl={3}>
                                <VenueCard venue={venue} />
                            </Col>
                            <Col md={6} xxl={6} className="flex-grow-1">
                                <Tabs
                                    defaultActiveKey="upcoming"
                                    variant="pills"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className='nav-pills-default' justify
                                >
                                    <Tab eventKey="upcoming" title="Upcoming Events">
                                        <UpcomingEventsList venue={venue} />
                                    </Tab>
                                    <Tab eventKey="packages" title="Packages">
                                        <PackagesList venue={venue} packages={packages} />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </section>
                </>
            )}
        </div>
    );
}
