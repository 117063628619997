import { useEffect } from "react"
import { OfferTooltip } from "./OfferTooltip";
import { PriceTooltip } from "./PriceTooltip";
import { SeatTooltip } from "./SeatTooltip";

export default function TooltipWrapper({
  dialogRef,
  tipPosition,
  tipContentType
}) {

  const renderTooltipContent = (tipContentType) => {
    switch (tipContentType) {
      case 1:
        return <SeatTooltip showFullSeatTip={false} />;
      case 2: 
        return <SeatTooltip showFullSeatTip={true} />
      case 3:
        return <PriceTooltip />;
      case 4:
        return <OfferTooltip isSingleSeat={false} />;
      case 5:
        return <OfferTooltip isSingleSeat={true} />;
      default:
        return null;
    }
  };


  return (
    <dialog
      ref={dialogRef}
      className={`vjx-tooltipWrapper seatmapTooltipArrow ${
        tipPosition.vertical + "--" + tipPosition.horizontal
      }`}
    >
      {renderTooltipContent(tipContentType)}
    </dialog>
  );
}
