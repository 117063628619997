import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  lazy,
} from "react";
import SeatmapModal from "./SeatmapModal/SeatmapModal";
import { useTickets } from "../TicketsProvider/TicketsProvider";
import ConfirmExitModal from "./ConfirmExitModal/ConfirmExitModal";
const SeatmapWrapper = lazy(() => import("./SeatmapWrapper/SeatmapWrapper"));

// Create the context
const SeatmapContext = createContext(undefined);

export const useSeatmap = () => {
  const context = useContext(SeatmapContext);
  if (!context) {
    throw new Error("Context must be used within a SeatmapProvider");
  }
  return context;
};

// Provide the functionality for displaying a seatmap inside a modal
export const SeatmapProvider = ({ children }) => {
  const dialogRef = useRef(null);
  const secondDialogRef = useRef(null);
  const { event, selectedFromMap, resetMapSelection } = useTickets();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState(null);
  const [background, setBackground] = useState(null);

  useEffect(() => {
    setData(event?.seatmap?.mapping);
    setBackground(event?.seatmap?.background);
  }, [event]);

  const showSeatmap = () => {
    setDialogOpen(true);
    dialogRef.current.showModal();
  };

  const hideSeatmap = () => {
    // Ask for confirmation if we have something selected
    if (selectedFromMap?.length > 0) {
      secondDialogRef.current.showModal();
    } else {
      confirmCloseMap();
    }
  };

  const unselectAllSeats = (seatIds) => {
    setData((prev) => {
      // Safety check for undefined prev
      if (!prev) return {};

      const { seats } = prev;

      const updatedSeats = {
        ...seats,
        ...Object.fromEntries(
          seatIds.map((seatId) => [
            seatId,
            { ...seats[seatId], selected: false },
          ])
        ),
      };

      return {
        ...prev,
        seats: updatedSeats,
      };
    });
  };

  const confirmCloseMap = () => {
    setDialogOpen(false);
    dialogRef.current.close();
    secondDialogRef.current.close();

    // First wipe the 'static-JSON' from all .selected values ( used to show seat in map-UI as selected )
    const allSelectedSeatIds = selectedFromMap
      .filter((ticketGroup) => ticketGroup?.seatId !== undefined)
      .map((ticketGroup) => ticketGroup.seatId);
    unselectAllSeats(allSelectedSeatIds);
    // then wipe the lists containing selected stuff
    resetMapSelection();
  };

  const cancelCloseMap = () => {
    secondDialogRef.current.close();
  };

  return (
    <SeatmapContext.Provider
      value={{ data, setData, showSeatmap, hideSeatmap, background }}
    >
      <SeatmapModal
        data={data}
        setData={setData}
        dialogRef={dialogRef}
        hideSeatmap={hideSeatmap}
        title={"Select your seats"}
        dialogOpen={dialogOpen}
        SeatmapWrapper={SeatmapWrapper} // make it lazy
      />
      <ConfirmExitModal
        dialogRef={secondDialogRef}
        confirm={confirmCloseMap}
        cancel={cancelCloseMap}
      />
      {children}
    </SeatmapContext.Provider>
  );
};
