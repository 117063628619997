import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import * as d3 from "d3";

import { ZoomLevel } from "./ZoomLevel";
import Legend from "./Legend/Legend";
import MapSheet from "./MapSheet/MapSheet";
import SheetBottom from "../../../../../../components/SheetBottom/SheetBottom";

import "./actionsProvider.scss";
import { MobileFSExit } from "./MobileFSExit";

// Create the context
const ActionsContext = createContext(undefined);

export const useActions = () => {
  const context = useContext(ActionsContext);
  if (!context) {
    throw new Error("Context must be used within a ActionsProvider");
  }
  return context;
};

// Create the provider component
export const ActionsProvider = ({
  handleClose,
  data,
  setData,
  loadingComplete,
  mapError,
  selectedFromMap,
  setSelectedFromMap,
  totalCount,
  totalPrice,
  eventTicketLimit,
  checkout,
  checkingAvailability,
  openTicketErrorModal,
  children,
}) => {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const zoomRef = useRef(null);
  const dialogRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [arePathsHidden, setArePathsHidden] = useState(false);
  const [scaleThreshold, setScaleThreshold] = useState(4);
  const hidePath = useRef(false);
  // Sheet controls
  const [desktopSheetOpen, setDesktopSheetOpen] = useState(false);

  // Open a sheet when user has selected one or more seats
  useEffect(() => {
    // selectedTickets.length > 0
    if (totalCount > 0) {
      if (window.innerWidth > 768) {
        setDesktopSheetOpen(true);
      }
    } else {
      setDesktopSheetOpen(false);
      closeMobileSheet();
    }
  }, [totalCount]);

  // Check if the sections are intersecting with view, if yes, draw seats
  const checkViewBoxIntersection = () => {
    const threshold = 1;
    // Get container boundingBox
    const container = containerRef.current.getBoundingClientRect();
    const [left1, top1, right1, bottom1] = [
      container.left,
      container.top,
      container.right,
      container.bottom,
    ];
    // Get polygons/sections from the svg
    const d3Svg = d3.select(svgRef.current);
    const svgChildren = d3Svg.selectChild(".polygons").selectChildren("g");

    const newSections = { ...data.sections };
    // Loop through polygons/sections
    svgChildren._groups[0].forEach((element) => {
      // Get sectionId from the element
      let sectionId = element.getAttribute("data-section-id");
      // Get sections boundingClientRect
      const child = element.getBoundingClientRect();
      const [left2, top2, right2, bottom2] = [
        child.left,
        child.top,
        child.right,
        child.bottom,
      ];
      // Check if the container is intersecting with the section
      const intersecting =
        left2 <= right1 - threshold &&
        top2 <= bottom1 - threshold &&
        bottom2 >= top1 - threshold &&
        right2 >= left1 - threshold;

      const wasIntersected = data.sections[sectionId]?.intersected;
      if (wasIntersected !== intersecting) {
        newSections[sectionId] = {
          ...newSections[sectionId],
          intersected: intersecting,
        };
      }
    });
    setData((prevData) => ({ ...prevData, sections: newSections }));
  };

  const afterZoomChange = (newScale) => {
    setScale(newScale);

    if (newScale > scaleThreshold) {
      hidePath.current = true;
      setArePathsHidden(true);
      checkViewBoxIntersection();
      return;
    }

    hidePath.current = false;
    setArePathsHidden(false);
  };

  const openMobileSheet = () => {
    let dialog = dialogRef.current;
    if (dialog) {
      dialog.showModal();
    }
  };

  const closeMobileSheet = () => {
    let dialog = dialogRef.current;
    if (dialog) {
      dialog.close();
    }
  };

  const addToSeats = (ticketGroup) => {
    setSelectedFromMap((prev) => [...prev, ticketGroup]);
  };

  const withinEventTicketLimit = (additionalTickets) => {
    if (!eventTicketLimit) return true;
    const currentGATickets = selectedFromMap
      .filter((ticketGroup) => ticketGroup.GA === true)
      .reduce((sum, ticketGroup) => sum + ticketGroup.quantity, 0);
    const currentSpecificSeats = selectedFromMap
      .filter((ticketGroup) => ticketGroup.GA === false)
      .map((ticketGroup) => ticketGroup.seatId).length;
    const totalCurrentTickets = currentGATickets + currentSpecificSeats;

    return totalCurrentTickets + additionalTickets <= eventTicketLimit;
  };

  const selectGASeats = (selectedGroups) => {
    const totalNewTickets = selectedGroups.reduce(
      (sum, { quantity }) => sum + quantity,
      0
    );

    if (!withinEventTicketLimit(totalNewTickets)) {
      openTicketErrorModal(
        "Max ticket limit reached",
        `Adding these tickets would exceed the ticket limit of ${eventTicketLimit}. Please change your selection.`
      );
      return;
    }

    selectedGroups.forEach((group) => {
      addToSeats(group);
    });
  };

  const selectSpecificSeat = (id, ticketGroup) => {
    if (!withinEventTicketLimit(1)) {
      openTicketErrorModal(
        "Max ticket limit reached",
        `Adding these tickets would exceed the ticket limit of ${eventTicketLimit}. Please change your selection if you really want this seat.`
      );
      return;
    }

    setData((prev) => {
      const { seats } = prev;
      const updatedSeats = {
        ...seats,
        [id]: { ...seats[id], selected: true },
      };
      return {
        ...prev,
        seats: updatedSeats,
      };
    });
    addToSeats({
      seatId: id,
      seatNumber: data.seats[id].seatNumber,
      ...ticketGroup,
    });
  };

  const unselectSeat = (seatId, ticketGroup) => {
    if (ticketGroup.GA) {
      const sectionId = ticketGroup.sectionId;
      const offerIds = ticketGroup.offerIds;

      setSelectedFromMap((prev) => {
        const updatedGroups = prev.map((ticketGroup) => {
          if (
            ticketGroup.sectionId === sectionId &&
            ticketGroup.offerIds === offerIds
          ) {
            // Decrease quantity by 1
            return {
              ...ticketGroup,
              quantity: ticketGroup.quantity - 1,
            };
          }
          return ticketGroup;
        });

        // Filter out any groups where quantity is 0 or less
        return updatedGroups.filter(
          (ticketGroup) => ticketGroup.GA && ticketGroup.quantity > 0
        );
      });
    } else {
      setSelectedFromMap((prev) =>
        prev.filter((ticketGroup) => ticketGroup.seatId !== seatId)
      );

      setData((prev) => {
        const { seats } = prev;
        const updatedSeats = {
          ...seats,
          [seatId]: { ...seats[seatId], selected: false },
        };
        return {
          ...prev,
          seats: updatedSeats,
        };
      });
    }
  };

  return (
    <ActionsContext.Provider
      value={{
        svgRef,
        setScale,
        scale,
        zoomRef,
        containerRef,
        selectSpecificSeat,
        selectGASeats,
        data,
        setData,
        unselectSeat,
        hidePath,
        arePathsHidden,
        setArePathsHidden,
        checkViewBoxIntersection,
        afterZoomChange,
        scaleThreshold,
        setScaleThreshold,
      }}
    >
      <div id='map' ref={containerRef}>
        <MobileFSExit handleClose={handleClose} />
        {children}
        {loadingComplete && !mapError && (
          <ControlsContainer
            desktopSheetOpen={desktopSheetOpen}
            zoomRef={zoomRef}
            scale={scale}
            totalCount={totalCount}
            totalPrice={totalPrice}
            eventTicketLimit={eventTicketLimit}
            openMobileSheet={openMobileSheet}
            checkout={checkout}
            checkingAvailability={checkingAvailability}
            afterZoomChange={afterZoomChange}
            selectedFromMap={selectedFromMap}
          />
        )}
      </div>
      <dialog ref={dialogRef} className='mobile-map-sheet-dialog'>
        <MapSheet
          canGoBack={true}
          goBack={closeMobileSheet}
          totalCount={totalCount}
          totalPrice={totalPrice}
          eventTicketLimit={eventTicketLimit}
          checkout={checkout}
          checkingAvailability={checkingAvailability}
          selectedFromMap={selectedFromMap}
        />
      </dialog>
    </ActionsContext.Provider>
  );
};

const ControlsContainer = ({
  desktopSheetOpen,
  zoomRef,
  scale,
  totalCount,
  totalPrice,
  eventTicketLimit,
  openMobileSheet,
  checkout,
  checkingAvailability,
  afterZoomChange,
  selectedFromMap
}) => (
  <div className='controls-container'>
    <div className='vjx-cc-outer'>
      <Legend />
      <div className='vjx-cc-inner'>
        <ZoomLevel
          zoomRef={zoomRef}
          scale={scale}
          afterZoomChange={afterZoomChange}
        />
        {desktopSheetOpen && (
          <MapSheet
            totalCount={totalCount}
            totalPrice={totalPrice}
            eventTicketLimit={eventTicketLimit}
            checkout={checkout}
            checkingAvailability={checkingAvailability}
            selectedFromMap={selectedFromMap}
          />
        )}
      </div>
    </div>
    <div className='mobile-only'>
      {totalCount > 0 && (
        <SheetBottom
          VSButton={true}
          VSClick={openMobileSheet}
          selectedTicketCount={totalCount}
          totalTicketsPrice={totalPrice}
          checkout={() => checkout(false)} // isQuickpick
          checkingAvailability={checkingAvailability}
        />
      )}
    </div>
  </div>
);
