import React from "react";
import Button from "react-bootstrap/Button";
import { useTooltips } from "../../TooltipProvider";
import { useActions } from "../../../ActionsProvider";
import { formatCurrency } from "../../../../../../../../../../utilities/helpers";

export default function SeatTooltip({ showFullSeatTip }) {
  const { hideTips, selectedSeatId, ticketGroup, data } = useTooltips();
  const { selectSpecificSeat } = useActions();

  let name;
  let price;
  let seatNumber = data?.seats[selectedSeatId]?.seatNumber;

  if (ticketGroup && ticketGroup.resale) {
    name = "Verified resale";
    price = ticketGroup.price;
  } else if (ticketGroup) {
    name = ticketGroup?.offer?.name;
    price = ticketGroup?.price;
  }

  const handleSelectSeat = (e) => {
    console.log("HANDLE SELECT SEAT")
    e.stopPropagation();
    selectSpecificSeat(selectedSeatId, ticketGroup);
    hideTips();
  };

  return (
    <div className='htmlTooltip'>
      <div className='htmlTop'>{name}</div>
      <div className='htmlBody'>
        <p className='htmlPrice'>{formatCurrency(price)}</p>
        <div className='htmlRow'>
          <HTMLSection label={"Section"} value={ticketGroup?.sectionNumber} />
          <HTMLSection label={"Row"} value={ticketGroup?.rowNumber} />
          <HTMLSection label={"Seat"} value={seatNumber || "error"} />
        </div>
        {showFullSeatTip && (
          <Button
            variant='secondary'
            size='lg'
            className='htmlButton'
            onClick={(e) => handleSelectSeat(e)}
          >
            Add now
          </Button>
        )}
      </div>
    </div>
  );
}

function HTMLSection({ label, value }) {
  return (
    <div className='htmlSection'>
      <p className='htmlPh'>{label}</p>
      <p className='htmlP'>{value}</p>
    </div>
  );
}
