import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SeatmapModal from "../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapModal/SeatmapModal";
import { PackageSeatmapWrapper } from "./PackageSeatmapWrapper";

// Create the context
const PackageSeatmapContext = createContext(undefined);

export const usePackageSeatmap = () => {
  const context = useContext(PackageSeatmapContext);
  if (!context) {
    throw new Error("Context must be used within a PackageSeatmapProvider");
  }
  return context;
};

// Provide the functionality for displaying a seatmap inside a modal
export const PackageSeatmapProvider = ({ eventPackage, children }) => {
  const dialogRef = useRef(null);
  const [selectedFromMap, setSelectedFromMap] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState(null);
  const [background, setBackground] = useState(null);

  useEffect(() => {
    setData(eventPackage?.seatmap?.mapping);
    setBackground(eventPackage?.seatmap?.background);
  }, [eventPackage]);

  const resetMapSelection = () => {
    console.log(".....")
  }

  const showSeatmap = () => {
    setDialogOpen(true);
    dialogRef.current.showModal();
  };

  const hideSeatmap = () => {
    confirmCloseMap();
  };

  const unselectAllSeats = (seatIds) => {
    setData((prev) => {
      // Safety check for undefined prev
      if (!prev) return {};

      const { seats } = prev;

      const updatedSeats = {
        ...seats,
        ...Object.fromEntries(
          seatIds.map((seatId) => [
            seatId,
            { ...seats[seatId], selected: false },
          ])
        ),
      };

      return {
        ...prev,
        seats: updatedSeats,
      };
    });
  };

  const confirmCloseMap = () => {
    setDialogOpen(false);
    dialogRef.current.close();

    // First wipe the 'static-JSON' from all .selected values ( used to show seat in map-UI as selected )
    const allSelectedSeatIds = selectedFromMap
      .filter((ticketGroup) => ticketGroup?.seatId !== undefined)
      .map((ticketGroup) => ticketGroup.seatId);
    unselectAllSeats(allSelectedSeatIds);
    // then wipe the lists containing selected stuff
    resetMapSelection();
  };

  return (
    <PackageSeatmapContext.Provider
      value={{ showSeatmap, background, eventPackage }}
    >
      <SeatmapModal
        data={data}
        setData={setData}
        dialogRef={dialogRef}
        hideSeatmap={hideSeatmap}
        title={"Select your seats"}
        dialogOpen={dialogOpen}
        SeatmapWrapper={PackageSeatmapWrapper}
      />
      {children}
    </PackageSeatmapContext.Provider>
  );
};
