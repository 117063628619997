import React from "react";
import { PackageDetails } from "./PackageDetails";

import "./viewPackageWrapper.scss";
import { PackageSeatmapProvider } from "./PackageSeatmapProvider";

export default function ViewPackageWrapper({ eventPackage }) {
  return (
    <PackageSeatmapProvider eventPackage={eventPackage}>
      <PackageDetails eventPackage={eventPackage} />
    </PackageSeatmapProvider>
  );
}
