import { formatCurrency, getSinglarOrPluralWord } from "../../../../utilities/helpers";

import Form from "react-bootstrap/Form";

import ViewSwiper from "../ViewSwiper/ViewSwiper";
import { SheetTop } from "../SheetTop";

import "./ticketDetails.scss";

export default function TicketDetails({
    toggleView,
    selectedObject,
    eventTicketLimit,
    changeQuickpickQuantity,
    mightAllowTicketCountChange = false,
    quickpickQuantity = 0,
}) {

    console.log("TicketDetails selectedObject: ", selectedObject);
    const isGeneralAdmission = selectedObject?.inventoryDBId !== undefined;
    const sectionId = selectedObject.sectionId;
    const selectionName = isGeneralAdmission
        ? "GENERAL ADMISSION"
        : `Sec ${selectedObject.sectionNumber} • Row ${selectedObject.rowNumber}`;
    const selectionDescription = selectedObject.offer?.description || selectedObject?.listing?.offer?.description

    return (
        <div className='vjx-td'>
            <SheetTop canGoBack={true} goBack={toggleView} />
            <ViewSwiper viewingSection={sectionId} smallButtons={true} />
            <div className={`vjx-tdd ${isGeneralAdmission ? 'ticket' : 'seat'}`}>
                <span className='vjx-tdd-s'>{selectionName}</span>
            </div>
            {mightAllowTicketCountChange && (
                <TicketCountChange
                    selectedObject={selectedObject}
                    changeQuickpickQuantity={changeQuickpickQuantity}
                    eventTicketLimit={eventTicketLimit}
                    quickpickQuantity={quickpickQuantity}
                />
            )}
            {selectionDescription && (
                <div className='vjx-td-desc'>
                    <span className='vjx-td-desc-1'>Ticket description</span>
                    <span className='vjx-td-desc-2'>{selectionDescription}</span>
                </div>
            )}
        </div>
    );
}

// This is intended to be only used with quickpicks
function TicketCountChange({
    selectedObject,
    changeQuickpickQuantity,
    eventTicketLimit,
    quickpickQuantity,
}) {
    const getTicketOptions = () => {
        const availableCount = selectedObject?.seatIds.length;
        const listing = selectedObject?.listing;
        const offer = selectedObject?.offer;

        const maxAllowed =
            eventTicketLimit !== null
                ? Math.min(eventTicketLimit, availableCount)
                : availableCount;

        if (listing) {
            return [Math.min(listing.quantity, maxAllowed)];
        }

        if (!offer) {
            return Array.from({ length: maxAllowed }, (_, index) => index + 1);
        }

        const max = Math.min(maxAllowed, offer.maxQuantity || maxAllowed);

        if (offer.limit) {
            return [Math.min(offer.limit, max)];
        } else if (offer.maxQuantity) {
            return Array.from({ length: max }, (_, index) => index + 1);
        } else if (offer.minQuantity) {
            return Array.from(
                { length: Math.max(0, max - offer.minQuantity + 1) },
                (_, index) => index + offer.minQuantity
            ).filter((num) => num <= max);
        } else if (offer.multipleOf) {
            const options = [];
            for (let i = offer.multipleOf; i <= max; i += offer.multipleOf) {
                options.push(i);
            }
            return options;
        } else {
            return Array.from({ length: max }, (_, index) => index + 1);
        }
    };

    const options = getTicketOptions();

    const selectionPrice = selectedObject?.offer
        ? selectedObject.price
        : selectedObject.listing.askingPrice;
    const selectionType = selectedObject?.offer
        ? selectedObject.offer.name
        : "Verified Resale";

    return (
        <div className='vjx-tcc'>
            <div className='d-flex flex-column'>
                <span className='vjx-tcc-span'>{selectionType}</span>
                <p className='vjx-tcc-p'>
                    {formatCurrency(selectionPrice)} ea{" "}
                    <span className='vjx-tcc-p-span'>incl. fees</span>
                </p>
            </div>
            <Form.Select
                value={quickpickQuantity}
                onChange={(e) => changeQuickpickQuantity(e.target.value)}
                aria-label='Default select example'
            >
                {options.map((number) => (
                    <option key={number} value={number}>
                        {number} {getSinglarOrPluralWord(number)}
                    </option>
                ))}
            </Form.Select>
        </div>
    );
}
