import Button from "react-bootstrap/Button";

export default function MobileFSExit({ handleClose }) {
  return (
    <Button variant='outline-light' className='expand' onClick={handleClose}>
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.5 5C2.5 3.34315 3.84315 2 5.5 2H7.5C8.05228 2 8.5 2.44772 8.5 3C8.5 3.55228 8.05228 4 7.5 4H5.5C4.94772 4 4.5 4.44772 4.5 5V7C4.5 7.55228 4.05228 8 3.5 8C2.94772 8 2.5 7.55228 2.5 7V5Z'
          fill='#777E90'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.5 19C2.5 20.6569 3.84315 22 5.5 22H7.5C8.05228 22 8.5 21.5523 8.5 21C8.5 20.4477 8.05228 20 7.5 20H5.5C4.94772 20 4.5 19.5523 4.5 19V17C4.5 16.4477 4.05228 16 3.5 16C2.94772 16 2.5 16.4477 2.5 17V19Z'
          fill='#777E90'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.5 19C22.5 20.6569 21.1569 22 19.5 22H17.5C16.9477 22 16.5 21.5523 16.5 21C16.5 20.4477 16.9477 20 17.5 20H19.5C20.0523 20 20.5 19.5523 20.5 19V17C20.5 16.4477 20.9477 16 21.5 16C22.0523 16 22.5 16.4477 22.5 17V19Z'
          fill='#777E90'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.5 5C22.5 3.34315 21.1569 2 19.5 2H17.5C16.9477 2 16.5 2.44772 16.5 3C16.5 3.55228 16.9477 4 17.5 4H19.5C20.0523 4 20.5 4.44772 20.5 5V7C20.5 7.55228 20.9477 8 21.5 8C22.0523 8 22.5 7.55228 22.5 7V5Z'
          fill='#777E90'
        />
      </svg>
    </Button>
  );
}
