import "./updatingProvider.scss";
import React, { createContext, useContext, useRef } from "react";
import { Spinner } from "../../../../../../../LoadingContainer/Spinner";

// Create the context
const UpdatingContext = createContext(undefined);

export const useUpdating = () => {
  const context = useContext(UpdatingContext);
  if (!context) {
    throw new Error("Context must be used within a UpdatingProvider");
  }
  return context;
};

// Provide certain type of modal used in the page
export const UpdatingProvider = ({ children }) => {
  const dialogRef = useRef(null);

  const startUpdating = (type) => {
    dialogRef.current.showModal();
  };

  const finishUpdating = () => {
    dialogRef.current.close();
  };

  return (
    <UpdatingContext.Provider
      value={{
        startUpdating,
        finishUpdating,
      }}
    >
      {children}
      <dialog ref={dialogRef} className='vjx-updating-modal'>
        <div className='updating-modal-content'>
          <Spinner variant='dark' size={"xl"} />
          <h4>Updating inventory</h4>
          <p>We’re retrieving your updated seats...</p>
        </div>
      </dialog>
    </UpdatingContext.Provider>
  );
};
