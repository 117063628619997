import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';

import { isValidPhoneNumber } from 'react-phone-number-input';

import authService from '../../../utilities/services/auth.service'

import { getSinglarOrPluralWord, toggleElement } from '../../../utilities/helpers';
import { createTicketTransfer } from '../../../utilities/api';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { BackButton } from '../../BackButton';
import { DisplayTickets } from '../DisplayTickets';
import { PhoneNumberInput } from '../../PhoneNumberInput';
import { Error } from '../../Error';
import { SuccessContainer } from '../SuccessContainer';
import { SuccessDisclaimer } from '../SuccessDisclaimer';
import { Spinner } from "../../LoadingContainer/Spinner"

export default function TransferModal({ guestList, handleClose, setTicketStatus, order, getMyOrders }) {

    const tickets = order?.tickets || guestList?.guest_passes;

    const { getUser } = authService;
    const user = getUser()?.user

    // 1 - tranfer 
    // 2 - phone number 
    // 3 - confirmation 
    // 4 - success
    const [
        step,
        setStep
    ] = useState(1);

    // select tickets
    const [
        selectedTickets,
        setSelectedTickets
    ] = useState([]);

    const [
        phoneNumber,
        setPhoneNumber
    ] = useState('');

    const [isValid, setIsValid] = useState(false)

    const [isSame, setIsSame] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    // reset validation
    useEffect(() => {
        setIsValid(true)
        setIsSame(false)
    }, [phoneNumber])

    useLayoutEffect(() => {
        let el = document.querySelector('.btn-close');

        if (el) {
            if (step === 4)
                toggleElement(el, false)

            return () => {
                toggleElement(el, true)
            }
        };
    }, [step])

    const handleGoBack = () => {
        setStep(step - 1)
    }

    const submit = (e) => {
        if (e) e.preventDefault();
        if (validNumber() && !isSameNumber()) {
            setStep(3)
        }
        else { setIsValid(false) }

        if (isSameNumber()) setIsSame(true)
    };

    const submitTransfer = () => {
        setIsSaving(true)
        let ticketIds = selectedTickets.map((ticket) => ticket.id)
        let data = {
            phoneNumber: phoneNumber,
            orderId: order.id,
            ticketIds
        };

        createTicketTransfer(data)
            .then((res) => {
                getMyOrders().then(() => {
                    setIsSaving(false)

                    if (!isSaving) setStep(4)
                })
            })
            .catch((err) => {
                console.error(err)
                setIsSaving(false)
            });
    }

    const validNumber = () => {
        return phoneNumber && isValidPhoneNumber(phoneNumber);
    };

    const isSameNumber = () => {
        return phoneNumber && phoneNumber == user?.phoneNumber
    }

    const handleClick = () => {
        handleClose();
        if (step === 4) setTicketStatus('transferred')
    };

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Transfer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 1 && (
                    <>
                        <DisplayTickets status="transfer" role='select' setSelectedTickets={setSelectedTickets} tickets={tickets} />
                        <Stack direction="horizontal" className="btn-group-flex">
                            <Button onClick={() => setStep(2)} className="btn-next" size="lg" disabled={selectedTickets.length === 0} >
                                Next
                            </Button>
                        </Stack>
                    </>)}
                {step === 2 && (
                    <Fragment>
                        <div className="modal-body-heading">
                            <h4 className="modal-body-heading-title">
                                Enter the recipient phone number{' '}
                            </h4>
                            <p className="small">
                                The recipient will get notified via sms that you have transferred your {getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()} to them.
                            </p>
                        </div>
                        <Form onSubmit={(e) => submit(e)}>
                            <Form.Group controlId="phone-number">
                                <Form.Label>Phone number</Form.Label>
                                <PhoneNumberInput
                                    phoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}
                                    hasError={!isValid}
                                    showMsg={!validNumber()}
                                    type="invalidPhone"
                                />
                                {/* is a valid phone number but is same phone number */}
                                {validNumber() && isSame &&
                                    (<Error type="transfer" />)
                                }
                            </Form.Group>
                        </Form>
                        <Stack direction="horizontal" className="btn-group-flex">
                            <BackButton variant="default" handleGoBack={handleGoBack} />
                            <Button
                                onClick={submit}
                                disabled={!phoneNumber || !isValid} size="lg" className="btn-next">
                                Transfer
                            </Button>
                        </Stack>
                    </Fragment>
                )}
                {step === 3 && (
                    <Fragment>
                        <div className="modal-body-heading">
                            <h4 className="modal-body-heading-title">
                                Are you sure you want to transfer these {getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()}?
                            </h4>
                            <DisplayTickets selectedTickets={selectedTickets} />
                        </div>
                        <div>
                            <p className='fw-medium text-muted mb-2'>Recipient phone number</p>
                            <span className='fs-md fw-semi-bold'>{phoneNumber}</span>
                        </div>
                        <Stack className="btn-group-flex">
                            <Button onClick={handleClose} variant="outline-light" size="lg">Cancel</Button>
                            <Button
                                onClick={submitTransfer}
                                disabled={isSaving}
                                size="lg"
                                className='icon-button btn-h'>
                                {isSaving ? (
                                    <Spinner />
                                ) : (
                                    'Transfer'
                                )}
                            </Button>
                        </Stack>
                    </Fragment>
                )}
                {step === 4 && (
                    <Fragment>
                        <SuccessContainer>
                            <h4 className="modal-body-heading-title">Your {getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()} have been transferred! </h4>
                        </SuccessContainer>

                        <p className="small">
                            Your transfer is pending till the recipient claims the {getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()}. You can cancel the transfer while it's pending. Once it's claimed by the recipient the {getSinglarOrPluralWord(selectedTickets?.length).toLowerCase()} will no longer be in your account.
                        </p>
                        <SuccessDisclaimer />

                        <Stack className="btn-group-flex">
                            <Link to="/my-transfers" className="btn btn-lg btn-outline-light">Go to My transfers</Link>
                            {tickets?.length > 0 && (<Button onClick={handleClick} size='lg'>Close</Button>)}
                        </Stack>
                    </Fragment>
                )}
            </Modal.Body>
        </Fragment>
    );
}
