import { useTickets } from "../../TicketsProvider/TicketsProvider";
import SheetBottom from "../../../../components/SheetBottom/SheetBottom";
import TicketDetails from "../../../../components/TicketDetails/TicketDetails";
import { useCheckout } from "../../CheckoutProvider";
import "./sideBottomModal.scss";

export default function SideBottomModal({ dialogRef, handleClose }) {
    const {
        selectedFromQuickpicks,
        eventTicketLimit,
        changeQuickpickQuantity
    } = useTickets();
    const { checkout, checkingAvailability } = useCheckout();

    const handleClick = (event) => {
        if (event.target === dialogRef.current) {
            handleClose();
        }
    };

    // Check if selectedFromQuickpicks and its properties exist
    const hasValidSelection =
        selectedFromQuickpicks &&
        (selectedFromQuickpicks.offer || selectedFromQuickpicks.listing);

    const totalPrice = hasValidSelection
        ? (selectedFromQuickpicks.offer
            ? selectedFromQuickpicks.price
            : selectedFromQuickpicks.listing.askingPrice) * selectedFromQuickpicks?.quantity
        : 0;

    return (
        <dialog id="side-bottom-modal" ref={dialogRef} onClick={handleClick} className='vjx-sbmodal'>
            <div className='sbmodal-inner'>
                <div className='sbmodal-content'>
                    {hasValidSelection ? (
                        <TicketDetails
                            toggleView={handleClose}
                            selectedObject={selectedFromQuickpicks}
                            eventTicketLimit={eventTicketLimit}
                            mightAllowTicketCountChange={true}
                            changeQuickpickQuantity={changeQuickpickQuantity}
                            quickpickQuantity={selectedFromQuickpicks?.quantity}
                        />
                    ) : (
                        <p>No ticket selected or invalid selection data.</p>
                    )}
                </div>
                <SheetBottom
                    totalTicketsPrice={totalPrice}
                    selectedTicketCount={selectedFromQuickpicks?.quantity}
                    checkout={() => checkout(true)} // is quickpick
                    checkingAvailability={checkingAvailability}
                />
            </div>
        </dialog>
    );
}
