import { formatCurrency } from "../../../../../../../../../../utilities/helpers";
import { useTooltips } from "../../TooltipProvider";

export default function PriceTooltip() {
  const { price } = useTooltips();
  return (
    <div className="purchaseTooltip">
      <p>From: {formatCurrency(price)}</p>
    </div>
  );
}
