import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { isValidPhoneNumber } from 'react-phone-number-input';
import InputMask from "comigo-tech-react-input-mask";

import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/user';

import { verifyUser, verifiyCode, createNewUser, validateEmail } from '../../utilities/api';
import { emailPatternMatch, namePatternMatch, isBlockedEmail } from "../../utilities/helpers";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

import { PhoneNumberInput } from './../PhoneNumberInput';
import { Error } from '../Error';
import { BackButton } from './../BackButton';
import { Spinner } from '../LoadingContainer/Spinner'

import './loginSignupForm.scss';

export default function LoginSignupForm() {

    const { setAuthenticated } = useContext(UserContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [
        step,
        setStep
    ] = useState(0);

    const [choice, setChoice] = useState('email');

    const [
        code,
        setCode
    ] = useState('');

    const [
        hasError,
        setHasError
    ] = useState(false);

    const [
        phoneNumber,
        setValue
    ] = useState();

    const [
        formValid,
        setFormValid
    ] = useState(false);

    const [
        firstName,
        setFirstName
    ] = useState('');

    const [
        lastName,
        setLastName
    ] = useState('');

    const [
        dob,
        setDob
    ] = useState('');

    const [
        email,
        setEmail
    ] = useState('');

    const [
        resendDisabled,
        setResendDisabled
    ] = useState(false)

    const [isEmailValid, setIsEmailValid] = useState(true)

    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true)

    const [isDobValid, setIsDobValid] = useState(true)

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [isSaving, setIsSaving] = useState(false)

    useEffect(
        () => {
            // reset when inputs are changed
            reset()
        },
        [
            phoneNumber,
            email,
            dob,
            firstName,
            lastName
        ]
    );

    // reset error when email changes
    useEffect(
        () => {
            setIsEmailValid(true);
        },
        [
            email
        ]
    );

    // reset error when phone number changes
    useEffect(
        () => {
            setIsPhoneNumberValid(true);
        },
        [
            phoneNumber
        ]
    );

    // reset error when dob changes
    useEffect(() => {
        setIsDobValid(true)
    }, [dob])

    useEffect(
        () => {
            if (firstName && lastName && email && phoneNumber && dob) {
                setFormValid(true);
            } else { setFormValid(false) }
        },
        [
            firstName,
            lastName,
            email,
            dob,
            phoneNumber
        ]
    );

    // reset fields when choice changes
    useEffect(() => {
        setValue()
        setEmail('')
        setCode('')
    }, [choice])

    const closeAlert = () => {
        setAlert({ show: false, variant: '', message: '' })
    }

    const reset = () => {
        // reset error when inputs are changed
        setHasError(false);
        // close alert when inputs change
        closeAlert()
        setResendDisabled(false)
    }

    const handleGoBack = () => {
        let curStep = step;
        setStep(--curStep);
    };

    const handleSwitch = () => {
        setChoice(choice === 'phone-number' ? 'email' : 'phone-number')
        setStep(0)
    }

    const isValidNumber = () => {
        return (phoneNumber && isValidPhoneNumber(phoneNumber))
    }

    const hasMaxDigits = (dob) => {
        // Remove '/' character from the input value
        const digits = dob.replace(/\//g, "");
        // Count the number of digits
        const digitCount = digits.replace(/\D/g, "").length;
        // Logic to limit the number of digits (example: 8 digits)
        return digitCount == 8;
    };

    const handleDob = (dob) => {
        if (!hasMaxDigits(dob)) {
            setIsDobValid(false)
            return;
        }
        const [month, day, year] = dob.split("/").map(Number);
        const dateObj = new Date(year, month - 1, day);

        setIsDobValid(dateObj.getFullYear() === year && dateObj.getMonth() === month - 1 && dateObj.getDate() === day);
    }

    function setVal() {
        // reset when input changes
        reset()
        const num1 = document.getElementById('num1');
        const num2 = document.getElementById('num2');
        const num3 = document.getElementById('num3');
        const num4 = document.getElementById('num4');
        const num5 = document.getElementById('num5');
        const num6 = document.getElementById('num6');

        if (num1.value) num2.focus();
        if (num1.value && num2.value) num3.focus();
        if (num1.value && num2.value && num3.value) num4.focus();
        if (num1.value && num2.value && num3.value && num4.value) num5.focus();
        if (num1.value && num2.value && num3.value && num4.value && num5.value) num6.focus();
        if (num1.value && num2.value && num3.value && num4.value && num5.value && num6.value) {
            const code = Number(`${num1.value}${num2.value}${num3.value}${num4.value}${num5.value}${num6.value}`);
            setCode(code);
            verifyUserCode(code);
        }
    }

    // submit phone number or email 
    function submit() {
        setIsSaving(true)
        if ((isValidNumber() || email) && !hasError) {
            let data = {
                data: {
                    phoneNumber: choice == 'email' ? '' : phoneNumber,
                    email
                }
            };
            verifyUser(data)
                .then((res) => {
                    setIsSaving(false)
                    setStep(1); // step - enter code
                })
                .catch((err) => {
                    setIsSaving(false)
                    setHasError(true);
                    console.error(err);
                });
        } else {
            setHasError(true)
            setIsSaving(false)
        }
    }

    function toPath() {
        let from = sessionStorage.getItem('location') ? sessionStorage.getItem('location') : '/browse';

        // either from state or session 
        if (location.state?.from) {
            from = location.state?.from?.pathname;
        }

        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        // sometimes the redirect to the checkout page appears blank because stripe elements cant get the token intime so adding a slight delay bofore the redirect
        setTimeout(() => {
            navigate(from, { replace: true });
        }, 500)
    }

    function verifyUserCode(code) {
        let data = {
            data: {
                code
            }
        };
        verifiyCode(data)
            .then((res) => {
                if (res.status === 200) {
                    AuthService.setUser(res.data);
                    setAuthenticated(res.data);
                    toPath();
                }
                else if (res.status === 203) {
                    setStep(2); // step - create account if email or phone number is not registered 
                }
            })
            .catch((err) => {
                setHasError(true);
                console.error(err);
            });
    }

    const submitForm = () => {
        setIsSaving(true)
        if (isValidNumber() && isEmailValid) {
            let data = {
                data: {
                    email,
                    firstName,
                    lastName,
                    phoneNumber,
                    dob
                }
            };
            createNewUser(data).then((res) => {
                if (res.status === 200) {
                    setIsSaving(false)
                    AuthService.setUser(res.data);
                    toPath();
                }

                if (res.status === 226) {
                    window.scrollTo(0, 0)
                    setIsSaving(false)
                    setFormValid(false)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Email or Phone number currently in use.'
                    })
                }
            });
        } else {
            setHasError(true)
            setIsSaving(false)
        }
    };

    const submitEmailRequest = (e) => {
        setIsSaving(true)
        let data = {
            email
        }
        if (isEmailValid && !isBlockedEmail(email)) {
            validateEmail(data)
                .then((res) => {
                    // check email against sendgrid validations
                    // Risky - uncommon domain 
                    // Risky with a suggestion - mispelled domain
                    // Valid with a suggestion eg. similar address
                    // Invalid - does not exist 
                    // verdict is risky or valid with a suggestion or invalid
                    if ((res.data.verdict === "Risky" || res.data.verdict === "Valid") && res.data.suggestion || res.data.verdict === "Invalid") {
                        setIsEmailValid(false)
                        setIsSaving(false)
                    } else {
                        submit()
                        setIsEmailValid(true)
                    }
                }).catch((err) => console.error(err))
        } else if (isBlockedEmail(email)) {
            setIsEmailValid(false)
            setIsSaving(false)
        }
    }

    const validatePattern = (val) => {
        // check email syntax
        const isMatch = emailPatternMatch(val)
        setIsEmailValid(isMatch)
    }

    const validateNumber = () => {
        setIsPhoneNumberValid(isValidNumber())
    }

    const resend = () => {
        submit()
        setResendDisabled(true)
        setAlert({
            show: true,
            variant: 'success',
            message: 'Verification Code has been resent.'
        })
    }

    const validateKeyPress = (e) => {
        if (e.key != 'Backspace') {
            // numbers only
            if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
            }
        } else return
    }

    return (
        <Row className="spacer-md" id="login-signup-container">
            <Col md={4}>{step > 0 && <BackButton h andleGoBack={handleGoBack} />}</Col>
            <Col className="form-container d-flex-column">
                {alert.show &&
                    <>
                        <Alert variant={alert.variant} className="mb-4">
                            <p>{alert.message}</p>
                        </Alert>
                    </>
                }
                {step === 0 && (
                    <Fragment>
                        <div className="heading">
                            <h1 className="fs-md">Login</h1>
                            <h2 className="normal text-muted fw-normal m-0">
                                The future of ticketing is here
                            </h2>
                        </div>
                        {choice == 'phone-number' ? (
                            <>
                                <div className="step-desc">
                                    <h3 className="title">Verify your mobile number</h3>
                                    <h4 className="subtitle">
                                        Select your country and enter your mobile number. You'll receive an access code via text message.
                                    </h4>
                                </div>
                                <Form.Group controlId="phone-number">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setValue} hasError={hasError} type='invalidPhone' />
                                </Form.Group>
                                <Button
                                    size="lg"
                                    className={`icon-button ${!isSaving ? 'btn-next' : ''} `}
                                    disabled={!phoneNumber || hasError || isSaving}
                                    onClick={submit}>
                                    {isSaving ? (
                                        <Spinner />
                                    ) : (
                                        'Validate'
                                    )}
                                </Button>
                                <span className='text-center small my-2'>or</span>
                                <Button
                                    size="lg"
                                    variant='outline-light'
                                    className="m-0 icon-button icon-button--black btn-next"
                                    onClick={() => setChoice('email')}>
                                    Use email
                                </Button>
                            </>
                        ) : (
                            <>
                                <div className="step-desc">
                                    <h3 className="title">Verify your email address</h3>
                                    <h4 className="subtitle">
                                        Enter your email address. You'll receive a verification code in your inbox.
                                    </h4>
                                </div>
                                <Form.Group className="form-group" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onBlur={(e) => validatePattern(e.target.value)}
                                        className={(email && hasError || !isEmailValid) ? 'error-border' : ''}
                                    />
                                    {email && hasError && isEmailValid && <Error val={email} />}

                                    {email && !isEmailValid && !hasError && <Error type="invalidEmail" val={email} />}
                                </Form.Group>

                                <Button
                                    size="lg"
                                    className={`icon-button ${!isSaving ? 'btn-next' : ''} `}
                                    disabled={!email || hasError || !isEmailValid || isSaving}
                                    onClick={submitEmailRequest}>
                                    {isSaving ? (
                                        <Spinner />
                                    ) : (
                                        'Validate'
                                    )}
                                </Button>
                                {/* <span className='text-center small my-2'>or</span>
                                <Button
                                    size="lg"
                                    variant='outline-light'
                                    className="m-0 icon-button icon-button--black btn-next"
                                    onClick={() => setChoice('phone-number')}>
                                    Use mobile number
                                </Button> */}
                            </>
                        )}
                    </Fragment>
                )}
                {step === 1 && (
                    <Fragment>
                        <div className="heading">
                            <h3 className="title mb-1">Enter 6 Digit Verification</h3>
                            <p className="subtitle">
                                Code was sent to <span className="text-secondary">{phoneNumber ? phoneNumber : email} </span>
                            </p>
                        </div>
                        <Form.Group>
                            <Form.Label>Enter Code</Form.Label>
                            <Stack direction="horizontal" gap={3}>
                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num1"
                                    pattern="^0[1-9]|[1-9]\d$"
                                    required
                                    onChange={setVal}
                                    onKeyDown={(e) => validateKeyPress(e)}
                                    className={hasError && 'error-border'}
                                />

                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num2"
                                    required
                                    onChange={setVal}
                                    onKeyDown={(e) => validateKeyPress(e)}
                                    className={hasError && 'error-border'}
                                />

                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num3"
                                    required
                                    onChange={setVal}
                                    onKeyDown={(e) => validateKeyPress(e)}
                                    className={hasError && 'error-border'}
                                />

                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num4"
                                    required
                                    onChange={setVal}
                                    onKeyDown={(e) => validateKeyPress(e)}
                                    className={hasError && 'error-border'}
                                />

                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num5"
                                    pattern="^0[1-9]|[1-9]\d$"
                                    required
                                    onChange={(e) => setVal(e)}
                                    className={hasError && 'error-border'}
                                />

                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    maxLength="1"
                                    id="num6"
                                    pattern="^0[1-9]|[1-9]\d$"
                                    required
                                    onChange={(e) => setVal(e)}
                                    className={hasError && 'error-border'}
                                />
                            </Stack>
                        </Form.Group>
                        {hasError && <Error type="code" />}
                        <Form.Text>
                            Haven't received your code? {choice === 'email' && 'Check your spam folder or'} <Button variant="link" onClick={() => resend()} disabled={resendDisabled}><span>{choice == 'phone-number' ? 'R' : 'r'}esend new code</span></Button>
                        </Form.Text>
                        {/* <span className='text-center small my-2'>or</span>
                        <Button
                            size="lg"
                            variant='outline-light'
                            className="m-0 icon-button icon-button--black btn-next"
                            onClick={handleSwitch}>
                            Use {choice === 'phone-number' ? 'email' : 'mobile number'}
                        </Button> */}
                    </Fragment>
                )}
                {step === 2 && (
                    <Fragment>
                        <div className="heading">
                            <h1 className="title">Let's Set Up your Profile</h1>
                        </div>
                        <Form className="d-flex-column">
                            <Form.Group className="form-group" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    required
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={(e) => validatePattern(e.target.value)}
                                    className={email && !isEmailValid ? 'error-border' : ''}
                                    disabled
                                />
                                {
                                    email && !isEmailValid &&
                                    (<Error type="invalidEmail" val={email} />)
                                }
                            </Form.Group>

                            <Form.Group className="form-group" controlId="phone-number">
                                <Form.Label>Mobile Number</Form.Label>
                                <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setValue} onBlur={validateNumber} hasError={!isPhoneNumberValid} type='invalidPhone' />
                            </Form.Group>

                            <Form.Group className="form-group" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    required
                                    pattern={namePatternMatch}
                                    value={firstName}
                                    onChange={(e) => setFirstName((name) => e.target.validity.valid || e.target.value === '' ? e.target.value : name
                                    )}
                                />
                            </Form.Group>
                            <Form.Group className="form-group" controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    required
                                    pattern={namePatternMatch}
                                    value={lastName}
                                    onChange={(e) => setLastName((name) => e.target.validity.valid || e.target.value === '' ? e.target.value : name
                                    )}
                                />
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>Birth Date</Form.Label>
                                <InputMask
                                    className={`form-control ${(dob && !isDobValid) ? 'error-border' : ''}`}
                                    mask="99/99/9999"
                                    placeholder="MM/DD/YYYY"
                                    maskPlaceholder={null}
                                    onChange={(e) => setDob(e.target.value)}
                                    onBlur={(e) => handleDob(e.target.value)}>
                                </InputMask>
                                <Form.Text className='text-left mt-1'>Format: MM/DD/YYYY</Form.Text>
                                {(dob && !isDobValid) && (
                                    <Error type="dob" />
                                )}
                            </Form.Group>
                            <Form.Group className="form-group fw-semi-bold" controlId="upcoming-events">
                                <Form.Check type="checkbox" label="Opt out of receiving emails and texts for our upcoming events" />
                            </Form.Group>
                            {hasError && <Error />}
                            <Button disabled={!formValid || !isEmailValid || !isDobValid || !isPhoneNumberValid || isSaving} size="lg" onClick={submitForm} className='icon-button'>
                                {isSaving ? (
                                    <Spinner />
                                ) : (
                                    'Sign up'
                                )}
                            </Button>
                        </Form>
                    </Fragment>
                )}
            </Col>
        </Row>
    );
}