import { useEffect, useState } from "react";
import { usePackageSeatmap } from "../PackageSeatmapProvider";
import { ActionsProvider } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/providers/ActionsProvider";
import { TooltipProvider } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/providers/TooltipProvider";
import { SeatMap } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/SeatMap";

export default function PackageSeatmapWrapper({ data, setData, handleClose }) {
  const { background, eventPackage } = usePackageSeatmap();

  const [mapError, setMapError] = useState(false);
  const [selectedFromMap, setSelectedFromMap] = useState([]);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [ticketLimit, setTicketLimit] = useState(50); // set this to be the package ticket limit
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [seatLookupTable, setSeatLookupTable] = useState(null);
  const [sectionLookupTable, setSectionLookupTable] = useState(null);

  const checkout = () => {
    console.log("need to implement...");
  };

  const openTicketErrorModal = () => {
    console.log("need to implement...");
  };

  const createSeatLookupTable = (ticketGroups) => {
    const lookupTable = {};
    ticketGroups.forEach((ticketGroup) => {
      if (ticketGroup.GA === false) {
        // Create a single reference object for this ticket group
        const ticketGroupRef = {
          ...ticketGroup,
          resale: false,
        };

        // Use the same reference for all seats in this group
        ticketGroup.seatIds.forEach((seatId) => {
          lookupTable[seatId] = ticketGroupRef;
        });
      }
    });

    return lookupTable;
  };

  const createSectionLookupTable = (ticketGroups) => {
    const lookupTable = {};

    ticketGroups.forEach((ticketGroup) => {
      // Get the sectionId from the quickpick
      const { sectionId } = ticketGroup;

      // Initialize array for this section if it doesn't exist
      if (!lookupTable[sectionId]) {
        lookupTable[sectionId] = [];
      }

      // Add this quickpick to the array for this section
      lookupTable[sectionId].push(ticketGroup);
    });

    return lookupTable;
  };

  useEffect(() => {
    // // Calculate total price
    // const seatedPrice = selectedFromSeats.reduce(
    //     (sum, item) => sum + item.seatInfo.price,
    //     0
    // );
    // const gaPrice = selectedFromGA.reduce(
    //     (sum, item) => sum + item.price * item.quantity,
    //     0
    // );
    // const newTotalPrice = seatedPrice + gaPrice;
    const seatedCount = selectedFromMap
      .filter((ticketGroup) => ticketGroup.GA === false)
      .map((ticketGroup) => ticketGroup.seatId).length;
    const gaCount = selectedFromMap
      .filter((ticketGroup) => ticketGroup.GA === true)
      .reduce((sum, ticketGroup) => sum + ticketGroup.seatIds.length, 0);

    const newTotalCount = seatedCount + gaCount;
    const newTotalPrice = 0;
    setTotalCount(newTotalCount);
    setTotalPrice(newTotalPrice);
  }, [selectedFromMap]);

  useEffect(() => {
    let getMapAvailability = async () => {
      try {
        console.log("eventPackage: ", eventPackage);
        const packageGroups = eventPackage?.package_groups;
        if (!packageGroups) return;

        // Create lookup tables; allows for constant time checks
        const sectionLookupTable = createSectionLookupTable(packageGroups);
        setSectionLookupTable(sectionLookupTable);
        const seatLookupTable = createSeatLookupTable(packageGroups);
        setSeatLookupTable(seatLookupTable);

        setLoadingComplete(true);
        console.log("LOADING COMPLETED....");
      } catch (error) {
        console.log("ERROR in getSectionAvailability: ", error);
        setMapError(true);
        setLoadingComplete(true);
      }
    };

    getMapAvailability();
  }, [eventPackage]);

  return (
    <ActionsProvider
      data={data}
      setData={setData}
      handleClose={handleClose}
      loadingComplete={loadingComplete}
      mapError={mapError}
      selectedFromMap={selectedFromMap}
      setSelectedFromMap={setSelectedFromMap}
      totalCount={totalCount}
      totalPrice={totalPrice}
      eventTicketLimit={ticketLimit}
      checkout={checkout}
      checkingAvailability={checkingAvailability}
      openTicketErrorModal={openTicketErrorModal}
    >
      <TooltipProvider data={data}>
        <SeatMap
          background={background}
          seatLookupTable={seatLookupTable}
          sectionLookupTable={sectionLookupTable}
          loadingComplete={loadingComplete}
          mapError={mapError}
          setMapError={setMapError}
          accessCodes={null}
        />
      </TooltipProvider>
    </ActionsProvider>
  );
}
